@charset "UTF-8";
/* CSS Document */
@import "../common/extend";

/*
  ------------------------------------
  ####################################
   #side style
  ####################################
  ------------------------------------
*/

.side{
    //width: 260px;
    width: 21.6666%;
   // margin-right: 30px;
    margin-right: 2.5%;
    float: left;
    .block_1,
    .block_2,
    .block_3,
    .block_4{
        max-width: 260px;
    }
    .picup_list{
        margin-bottom: 25px;
        li{
            margin-bottom: 2px;
            a{
                display: block;
                img{
                    width: 100%;
                }
            }
        }
    }
    .category_title{
        width: 100%;
        text-align: center;
        //font-size: 18px;
        font-size: 128.57%;
        color: #fff;
        border-radius: 3px;
        padding: 0.92em;
         box-sizing: border-box;
         background-color: #f80;
        @include sitesize {
                font-size: 1.636vw;
            }
    }
    .block_2{
        background-color: #fff;

    }
    .category_list{
        padding: 0 10px;
        li{
            width: 100%;
            text-align: center;
            color: #666;
            //font-size: 18px;
            font-size: 128.57%;
            box-sizing: border-box;
            background: url("https://gigaplus.makeshop.jp/kyotobin/assets/img/common/line2.png") repeat-x bottom;
            @include sitesize {
                font-size: 1.636vw;
            }
            a{
                display: block;
                padding:0.92em;
                color: #666;
            }
        }
    }
    .block_3{
        background-color: #fff;
         margin-bottom: 10px;
    }
    .info_list{
        padding: 22px 10px;
         li{
            width: 100%;
            //font-size: 14px;
            font-size: 100%;
            color: #666;
            box-sizing: border-box;
            background: url("https://gigaplus.makeshop.jp/kyotobin/assets/img/common/icon1.png") no-repeat left center;
            @include sitesize {
               font-size: 1.272vw;
            }
             a{
                display: block;
                padding:0.72em 24px;
                color: #666;
             }
        }
    }
    .sns_list{
         li{
            width: 100%;
            text-align: center;
            //font-size: 18px;
            font-size: 128.57%;
            color: #fff;
            border-radius: 5px;
            box-sizing: border-box;
            margin-bottom: 10px;
            @include sitesize {
                font-size: 1.636vw;
            }
             a{
                display: block;
                padding:1em;
                color: #fff;
             }
        }
        .fb{background: 
            url("https://gigaplus.makeshop.jp/kyotobin/assets/img/common/icon_fb.png") no-repeat left 5.7% center;
            background-color: #cebebe;
        }
        .tw{background: 
            url("https://gigaplus.makeshop.jp/kyotobin/assets/img/common/icon_tw.png") no-repeat left 5.7% center;
            background-color: #cebebe;
        }
        .insta{background: 
            url("https://gigaplus.makeshop.jp/kyotobin/assets/img/common/icon_insta.png") no-repeat left 5.7% center;
            background-color: #cebebe;
        }
    }
    @include tublet{
        display: none;
    }
}